<template>
  <div>
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div 
  class="d-flex align-items-center justify-content-between" 
  style="width: 100%; cursor: pointer;"
  @click="navigateToDashboard"
>
  <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
</div>
      <nav class="d-flex align-items-center justify-content-between" style="width: 100%; margin-top:20px">
        <button 
          class="regresar-button" 
          style="margin-left:20px;"
          @click="$router.back();">
          <img :src="BackIcon" alt="Back" class="back-icon" style="width:auto; height:20px" />
         Regresar
        </button>
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ credits }}
        </button>
      </nav>
    </header>
    <div class="container">
      <div class="row justify-content-center" >
        <div class="col-md-8">
          <div class="card mb-2">
            <div class="card-body">
              <h5 class="text-center mb-2 mt-4">Depósito en Efectivo</h5>
              <p class="card-text" style="text-align: left; margin-top: 20px;">
                1. Acude a cualquier tienda de conveniencia que acepte depósitos en efectivo. <br><br>
                2. Proporciona los siguientes datos de cuenta: <br><br>
                Banco: SANTANDER <br>
                Tarjeta: 5579 1004 1213 0922  <br>
                Beneficiario: ANDRES GARZAFOX <br>
                Monto: ${{ monto }} MXN <br>
                Concepto: CONSULTA DERMATOLOGICA <br><br>
                3. Envía el comprobante a la plataforma dando clic en el botón.<br>
                4. Una vez que se confirme la transaccion tu consulta sera procesada.*<br>
                </p>
                <p class="card-text" style="text-align: left; margin-top: 20px; font-size:10px">
                  *Las transacciones realizadas a traves de este metodo de pago pueden tardar 1 a 2 dias habiles en verse reflejadas en nuestro estado de cuenta.
                 </p>
                  <div class="text-center">
                    <label for="images" class="btn btn-primary" style="width:80%">Enviar comprobante</label>
                    <input class="d-none" type="file" id="images" name="images" accept="image/*" multiple ref="imagesRef" @change="updateReceiptFile">
                </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { mapState } from 'vuex';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // this generates a unique identifier

import useFacebookPixel from '@/composables/useFacebookPixel';

import { onMounted } from 'vue';


export default {
  setup() {
    const { track } = useFacebookPixel();


    onMounted(() => {

      track('ViewContent', {content_name: 'Cash Payments' });

    });

    return {};
  }, 
  data() {
  return {
      assessment_id: null,
      HeaderLogo: require('@/assets/dermapp-white.png'),
      BackIcon: require('@/assets/arrow-back.png'),
      monto:180,
      receiptFiles: [],  // New data property
      codeName: '' 
  };
},
  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'profileName', 'profileLastName', 'profileBirthday', 'primaryName', 'primaryBirthday']),
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refrescar el token
          const idToken = await user.getIdToken(true); // El 'true' fuerza a refrescar el token

          // Actualizar localStorage con el nuevo token
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
          this.assessment_id = this.$route.params.id;
          this.userAgent = navigator.userAgent; // Store the user agent

           // Now fetch the has_coupon status
           await this.fetchHasCouponStatus();
        } catch (error) {
          console.error('Error al renovar el token:', error);

          // Si el token está expirado o hay un error de autenticación
          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token'); // Limpiar el token expirado
            this.$router.push('/SignIn'); // Redirigir al login
          }
        }
      } else {
        // Si no hay un usuario autenticado, redirigir al login
        this.$router.push('/SignIn'); // Redirigir al login
      }
    });



},
  methods: {
    navigateToDashboard() {
      if (this.email) {
        this.$router.push({ 
          path: '/patient-dashboard', 
          query: { email: this.email } 
        }).catch(err => {
          // Handle the error if navigation fails (optional)
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        // Handle the case where email is not available
        console.warn('Email is not defined.');
      }
    },

    async getLocationByIp(ipAddress) {
  const response = await axios.get(`https://ipinfo.io/${ipAddress}/geo`);
  return { city: response.data.city, state: response.data.region, zipCode: response.data.postal };
},

 // Separate function to fetch has_coupon status
 async fetchHasCouponStatus() {
    try {
      // Ensure that assessment_id is available
      if (!this.assessment_id) {
        console.warn('Assessment ID is not set.');
        return;
      }

      // Fetch `has_coupon` status and `code_name` from the backend
      const response = await axios.get(`${this.$baseURL}/api/get-has-coupon-status/${this.assessment_id}`);

      if (response.status === 200) {
        const { has_coupon, code_name } = response.data;

        if (has_coupon === 1 && code_name) {
          this.codeName = code_name; // Set the codeName data property

          // Adjust `monto` based on `code_name` using a switch statement
          switch (code_name) {
            case 'CONSULTA30':
              case 'D3RM4PP30':
              this.monto = 120;
              break;
              case 'CONSULTA50':
              case 'DERMAPP50':
              this.monto = 90;
              break;
            default:
              this.monto = 180;
          }
        } else {
          // If no coupon is applied
          this.monto = 180;
          this.codeName = '';
        }
      } else {
        console.warn('Failed to fetch coupon status. Using default monto.');
        this.monto = 180;
        this.codeName = '';
      }
    } catch (error) {
      console.error('Error fetching has_coupon status:', error);
      this.handleAlert('Error al obtener la información de la evaluación.', 'error');
      // Optionally, set default monto
      this.monto = 180;
      this.codeName = '';
    }
  },


async getIpAddress() {
  const response = await axios.get('https://api64.ipify.org?format=json');
  return response.data.ip;
},

getFbcAndFbp() {
  let fbc, fbp;

  try {
    // Extract _fbc from the cookie
    fbc = document.cookie.split('; ').find(row => row.startsWith('_fbc')).split('=')[1];
  } catch {
    fbc = null;
  }

  try {
    // Extract _fbp from the cookie
    fbp = document.cookie.split('; ').find(row => row.startsWith('_fbp')).split('=')[1];
  } catch {
    fbp = null;
  }

  return { fbc, fbp };
},

    updateReceiptFile(event) {
      this.receiptFiles = [...event.target.files];
      if(this.receiptFiles.length>0){
        this.uploadReceipt();
      }
      
  },

  async uploadToS3(file, folder) {
  const fileExtension = file.name.split('.').pop();
  const newFileName = `${uuidv4()}.${fileExtension}`; // creates a new unique file name
  const fileName = `${folder}${newFileName}`;
  const destination = 'dermapp-documents';
  const availability = 'public-read';

  const formData = new FormData();
  formData.append('destination', destination);
  formData.append('fileName', fileName);
  formData.append('file', file);
  formData.append('availability', availability);

  try {
    await axios.post(`${this.$baseURL}/api/upload-to-s3`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    await this.uploadToDB(newFileName, fileExtension);
  } catch (error) {
    console.error('Error uploading files to s3:', error);
  }
},

async uploadToDB(fileName, fileExtension){
let fileType = 2;
    
    try {
      const files_data = {
        assessment_id: this.$route.params.id,
        file_name: fileName,
        file_extension: fileExtension,
        file_type: fileType,
      }


await axios.post(`${this.$baseURL}/api/assessment-files`, files_data);

let { fbp, fbc } = this.getFbcAndFbp();
  let ipAddress = await this.getIpAddress();
  let { city, state, zipCode } = await this.getLocationByIp(ipAddress);

let userData = {
    email: this.email,
    phone: this.phone,
    name: this.profileName,
    lastName: this.profileLastName,
    sex: this.profileSex,
    userId: this.userId.toString(),
    fbp: fbp,
    fbc: fbc,
    birthday: this.profileBirthday,
    ipAddress: ipAddress,
    userAgent: this.userAgent,
    country: this.country,
    state: state,
    city: city,
    zipCode: zipCode,
  }

const payment_data = {
        assessment_id: this.$route.params.id,
        status: 1,
        payment_method: 'cash-deposite',
        phone: this.phone,
        email: this.email,
        patientName: this.profileName,
        userData: userData,
      }

await axios.put(`${this.$baseURL}/api/update-assessment-payment-v2`, payment_data);

              this.$router.push({ name: 'PaymentSuccess', params: { email: this.email} });

      


} catch (error) {
console.error('Error creating assessment:', error);
}
},

async uploadReceipt() {

      try {
          // Make a POST request to your API endpoint to create the assessment

              // Now that we have an assessmentId, we can upload the files
              const images = this.$refs.imagesRef.files;
              for(let i=0; i<images.length; i++) {
                  await this.uploadToS3(images[i], 'receipts/');
              }

          
          }
      catch (error) {
          console.error('Error uploading receipt:', error);
      }
  },
  }
};
</script>

<style scoped>
.regresar-button {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  width: auto;
  margin-right: 20px; /* Ajusta según sea necesario */
  border: 2px solid #0D80FF;
  border-radius: 10px;
  background-color: #0D80FF; /* Color de fondo azul */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transición suave opcional */
}

.regresar-button:hover {
  background-color: #0B6CD8; /* Tono más oscuro al pasar el cursor */
}


.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}

.nav-item {
margin-right: 20px;
background: none;
border: none;
color: white;
cursor: pointer;
}

</style>
