<template>
  <div>
    <header class="header d-flex flex-column align-items-start justify-content-between" style="width: 100%; padding: 20px">
      <div 
        class="d-flex align-items-center justify-content-between" 
        style="width: 100%; cursor: pointer;"
        @click="navigateToDashboard"
      >
        <img :src="HeaderLogo" alt="CreateLogo" class="create-logo" style="height:30px;"/>
      </div>
      <nav style="width: 100%; margin-top:20px; text-align:right">
        <button 
          class="nav-item" 
          style="padding-left:10px; padding-right:10px; height:40px; width:auto; margin-right:20px; border: 2px solid white; border-radius: 10px; background: none; color: white; cursor: pointer;" 
          @click="$router.push({ name: 'CreditsHistory', params: { id: userId } })">
          Créditos: {{ credits }}
        </button>
      </nav>
    </header>
    <div class="container d-flex align-items-center" style="min-height: 100vh; width: 100vw; flex-direction: column;">
      <div class="card-container" style="width: 400px; max-width: 100%;">
        <div v-if="isDataLoaded" class="card mb-3">
          <div class="card-body">
            <h5 class="text-left mb-2 mt-4">¡Tu pago ha sido recibido exitosamente!</h5>
            <p>
              Ingresa los datos de envio o revisa que sean correctos.
            </p>
            <div class="card">
              <div class="card-body">
                <h2 class="card-title text-center mb-4">Datos de envio</h2>
                <form @submit.prevent="createDeilveryAddress()" style="text-align: left;">
                  <div class="mb-3">
                    Nombre completo
                    <input 
                      type="text" 
                      class="form-control" 
                      id="name" 
                      v-model="address.name" 
                      placeholder="Ingresa tu nombre completo" 
                      required
                    >
                  </div>

                  <div class="mb-3">
                    <label for="phone">Teléfono de contacto</label>
                    <input 
                      type="text" 
                      class="form-control"
                      id="phone"
                      v-model="address.phone"
                      placeholder="Ingresa los 10 dígitos de tu teléfono"
                      maxlength="10"
                      inputmode="numeric"
                      @keypress="validateInput($event)"
                      :class="{'border-danger': address.phone.length > 0 && address.phone.length !== 10}"
                    >
                  </div>

                  <div class="mb-3" style="position: relative;">
                    <label for="zip_code" class="form-label">Código postal</label>
                    <input
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': zipCodeError }"
                      id="zip_code"
                      v-model="address.zip"
                      @input="onInputChange"
                      placeholder="Ingresa 5 dígitos Ej. 64753"
                      maxlength="5"
                      required
                    />
                    <!-- Available districts dropdown -->
                    <div v-if="availableDistricts.length > 0" class="available-districts">
                      <ul>
                        <li v-for="(option, index) in availableDistricts" :key="index" @click="selectDistrict(option)">
                          {{ option.displayText }}
                        </li>
                      </ul>
                    </div>
                    <!-- Error message -->
                    <div class="invalid-feedback" v-if="zipCodeError">{{ zipCodeError }}</div>
                  </div>

                  <div class="mb-3">
                    Estado
                    <input 
                      type="text" 
                      class="form-control" 
                      id="state" 
                      v-model="address.state" 
                      placeholder="Ingresa tu estado" 
                      required 
                      readonly
                    >
                  </div>
                  
                  <div class="mb-3">
                    Municipio/Alcaldia
                    <input 
                      type="text" 
                      class="form-control" 
                      id="city" 
                      v-model="address.city" 
                      placeholder="Ingresa tu ciudad" 
                      required 
                      readonly
                    >
                  </div>

                  <div class="mb-3">
                    Colonia
                    <input 
                      type="text" 
                      class="form-control" 
                      id="district" 
                      v-model="address.district" 
                      placeholder="Ingresa tu colonia" 
                      required 
                      readonly
                    >
                  </div>

                  <div class="mb-3">
                    Calle
                    <input 
                      type="text" 
                      class="form-control" 
                      id="street" 
                      v-model="address.street" 
                      placeholder="Ingresa tu calle" 
                      required
                    >
                  </div>

                  <div class="mb-3">
                    <div class="d-flex flex-wrap">
                      <div class="me-3" style="flex: 1 1 45%;">
                        <label for="numero_exterior">Número Exterior</label>
                        <input 
                          type="text" 
                          class="form-control" 
                          id="numero_exterior" 
                          v-model="address.external" 
                          placeholder="Ej. 1234"
                          maxlength="50" 
                          required
                        >
                      </div>
                      <div style="flex: 1 1 45%;">
                        <label for="numero_interior">Interior/Dpto</label>
                        <input 
                          type="text" 
                          class="form-control" 
                          id="numero_interior" 
                          v-model="address.internal" 
                          placeholder="Ej. Apt 5B"
                          maxlength="50"
                        >
                      </div>
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="is_between">Entre calles</label>
                    <div class="d-flex">
                      <input 
                        type="text" 
                        class="form-control me-2" 
                        id="is_between_1" 
                        v-model="address.is_between_1" 
                        placeholder="Calle 1"
                        maxlength="50" 
                        required
                        style="flex: 1;"
                      >
                      <input 
                        type="text" 
                        class="form-control" 
                        id="is_between_2" 
                        v-model="address.is_between_2" 
                        placeholder="Calle 2" 
                        maxlength="50"
                        style="flex: 1;"
                      >
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="guidelines">Referencias</label>
                    <input 
                      type="text" 
                      class="form-control" 
                      id="guidelines" 
                      v-model="address.guidelines" 
                      placeholder="Ej. Casa azul con zaguan rojo" 
                      maxlength="50" 
                      required
                    >
                  </div>

                  <div style="text-align:center">
                    <button type="submit" class="btn btn-primary" style="width: 80%;">
                      Guardar dirección
                    </button>
                  </div>
                </form>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
    
</template>

<script>
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { mapActions, mapState } from 'vuex';
import useFacebookPixel from '@/composables/useFacebookPixel';
import axios from 'axios';
import { onMounted } from 'vue';

export default {
  setup() {
    const { track } = useFacebookPixel();

    onMounted(() => {
      track('ViewContent', {content_name: 'Pharmacy Payment Success' });
    });

    return {};
  }, 
  data() {
    return {
      availableDistricts: [],
      zipCodeError: '',
      nextChargeDate: '',
      isDataLoaded: false,
      address: {
        id: null,
        user_id: null,
        name: '',
        street: '',
        number: '',
        zip: '',
        district: '',
        city: '',
        state: '',
        guidelines: '',
        is_between: '',
        phone: '',
        external: '',
        internal: '',
        is_between_1: '',
        is_between_2: '',
      },
      mode: 0,
      HeaderLogo: require('@/assets/dermapp-white.png'),
      BackIcon: require('@/assets/arrow-back.png')
    };
  },
  computed: {
    ...mapState(['email', 'userId', 'isSubscribed', 'credits', 'country', 'phone', 'referralCode', 'profileId', 'profileSex', 'primaryName', 'primaryBirthday']),
  },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Refresh the token
          const idToken = await user.getIdToken(true);
          localStorage.setItem('Token', idToken);
          localStorage.setItem('Email', user.email);
          const token = localStorage.getItem('Token');
          
          axios.get(`${this.$baseURL}/api/user-data-v2`, {
            params: {
              email: this.email || this.$route.query.email,
              page: 'Pharmacy Payment Success'
            },
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
          .then((response) => {
            const data = response.data.data;
            this.updateUserId(data.id);
            this.updateEmail(data.email);
            this.updatePhone(data.phone, data.country);
            this.updateCountry(data.country);
            this.updateCredits(data.credits);
            this.updateReferralCode(data.referral_code);

            axios.get(`${this.$baseURL}/api/user-address`, {
              params: {
                user_id: data.id,
              },
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
            .then((address_response) => {
              if(address_response.data.success === true){
                this.mode = 1;
                this.address = address_response.data.data;
                // Parse is_between
                const betweenSplit = this.address.is_between.split('y');
                this.address.is_between_1 = betweenSplit[0] ? betweenSplit[0].trim() : '';
                this.address.is_between_2 = betweenSplit[1] ? betweenSplit[1].trim() : '';
                // Parse number into external/internal
                const numberSplit = this.address.number.split('int');
                this.address.external = numberSplit[0] ? numberSplit[0].trim() : '';
                this.address.internal = numberSplit[1] ? numberSplit[1].trim() : '';
              }
              this.isDataLoaded = true;
            })
            .catch((error) => {
              console.error("Error fetching user address:", error);
              this.isDataLoaded = true;
            });
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
          });
        } catch (error) {
          console.error('Error al renovar el token:', error);

          if (error.code === 'auth/id-token-expired' || error.code === 'auth/user-token-expired' || error.message.includes('Token')) {
            localStorage.removeItem('Token');
            this.$router.push('/SignIn');
          }
        }
      } else {
        this.$router.push('/SignIn');
      }
    });
  },
  methods: {
    navigateToDashboard() {
      if (this.email) {
        this.$router.push({ 
          path: '/patient-dashboard', 
          query: { email: this.email } 
        }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      } else {
        console.warn('Email is not defined.');
      }
    },

    validateInput(event) {
      const charCode = event.charCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },

    onInputChange() {
      if (this.address.zip.length === 5) {
        this.validateZipCode();
      } else {
        this.availableDistricts = [];
        this.address.state = '';
        this.address.city = '';
        this.address.district = '';
        this.zipCodeError = '';
      }
    },

    async validateZipCode() {
      try {
        let zipCode = {
          zip_code: this.address.zip
        };
        const response = await axios.post(`${this.$baseURL}/api/validate-zipcode`, zipCode);
        if (response.status === 200) {
          let addressData = response.data;
          let districtsArray = addressData.district.replace(/^\[|\]$/g, '').split(',').map(district => district.trim());
          
          this.availableDistricts = [];
          districtsArray.forEach(district => {
            district = district.replace(/^'|'$/g, '');
            this.availableDistricts.push({
              state: addressData.state,
              city: addressData.city,
              district: district,
              displayText: `${addressData.zip_code}, ${district}, ${addressData.city}, ${addressData.state}`
            });
          });
          this.zipCodeError = '';
        }
      } catch (error) {
        console.error('Error validating zip code:', error);
        this.availableDistricts = [];
        this.address.state = '';
        this.address.city = '';
        this.address.district = '';
        this.zipCodeError = 'El código postal no es válido';
      }
    },

    selectDistrict(option) {
      this.address.state = option.state;
      this.address.city = option.city;
      this.address.district = option.district;
      this.availableDistricts = [];
    },

    ...mapActions(['updateUserId', 'updateEmail', 'updatePhone', 'updateCountry', 'updateCredits', 'updateReferralCode', 'updateProfileId', 'updateProfileName', 'updateProfileSex', 'updateProfileSubscription']),

    async createDeilveryAddress() {
      // Recombine is_between and number fields
      this.address.is_between = this.address.is_between_1 + " y " + this.address.is_between_2;
      this.address.number = this.address.external + (this.address.internal ? " int " + this.address.internal : "");

      let temp_address = {
        user_id: this.userId,
        name: this.address.name,
        street: this.address.street,
        number: this.address.number,
        zip: this.address.zip,
        district: this.address.district,
        city: this.address.city,
        state: this.address.state,
        guidelines: this.address.guidelines,
        is_between: this.address.is_between,
        phone: this.address.phone,
      }

      if(this.mode === 0) {
        const response = await axios.post(`${this.$baseURL}/api/create-delivery-address`, temp_address);
        if (response.status === 200) {
          this.$router.push({ name: 'PatientDashboard' });
        }
      } else {
        const response = await axios.put(`${this.$baseURL}/api/update-delivery-address-v2`, temp_address);
        if (response.status === 200) {
          this.$router.push({ name: 'PatientDashboard' });
        }
      }
    }
  },
};
</script>

<style scoped>
.header {
  margin-top: -5px;
  position: relative;
  width: 100%;
  height: auto;
  background: #36b5ff;
  z-index: 1;
}

.nav-item {
  margin-right: 20px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.regresar-button {
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  width: auto;
  margin-right: 20px; 
  border: 2px solid #0D80FF;
  border-radius: 10px;
  background-color: #0D80FF; 
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.regresar-button:hover {
  background-color: #0B6CD8;
}

.available-districts {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  margin-top: -1px;
  position: absolute;
  width: calc(100% - 2px);
  background: #fff;
  z-index: 10;
}
.available-districts ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.available-districts li {
  padding: 8px 12px;
  cursor: pointer;
}
.available-districts li:hover {
  background-color: #f0f0f0;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.is-invalid {
  border-color: #dc3545;
}

.invalid-feedback {
  color: #dc3545;
}
</style>
